<template>
  <div class="hello">
    <h3>安装包下载页面</h3>
    <el-table
      :data="apklists"
      style="width: 95%; margin-left: 2%; margin-top: 20px;"
      :header-cell-style="{ color: '#a38972', background: '#ebf7f7' }"
    >
      <el-table-column prop="name" label="安装包" align="center"></el-table-column>
      <el-table-column prop="type" label="包类型" align="center">
        <template slot-scope="scope">
    <el-tag type="success">{{scope.row.type}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="version" label="版本" align="center"></el-table-column>
      <el-table-column prop="size" label="包大小" align="center"></el-table-column>
      <el-table-column align="center" label="下载" min-width="120">
        <template slot-scope="scope">
          <el-button
            type="success"
            plain
            icon="el-icon-bottom"
            size="mini "
            @click="download(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      apklists: [
        {
          name: "粮食储藏安全测控管理系统",
          type: "apk",
          version: "最新版",
          size: "21.5MB"
        },
        {
          name: "港口设备安全在线监测系统",
          type: "apk",
          version: "最新版",
          size: "7.4MB"
        }
      ]
    };
  },
  methods: {
    download(row) {
      if(row.name == "粮食储藏安全测控管理系统") {
      let a = document.createElement("a");
      a.href = "/粮食储藏安全测控管理系统.apk";
      a.download = "粮食储藏安全测控管理系统.apk";
      a.click();

      } else if (row.name == "港口设备安全在线监测系统")  {
             let a = document.createElement("a");
      a.href = "/港口设备安全在线监测系统.apk";
      a.download = "港口设备安全在线监测系统.apk";
      a.click();

      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
